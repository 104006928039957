import axios from "axios"
import { navigate } from "gatsby"
import React from "react"
import { Provider } from "react-redux"
import { applyMiddleware, createStore } from "redux"
import axiosMiddleware from "redux-axios-middleware"
import { composeWithDevTools } from "redux-devtools-extension"
import { persistReducer, persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"
import AsyncStorage from "@react-native-async-storage/async-storage"
import thunk from "redux-thunk"
import { clearApiErrors, logoutUser, setApiErrors } from "~actions/index"
import { baseUrl } from "~helpers/constants"
import { outsideTokenRefreshRange, tryAndRefresh } from "~helpers/helpers"
import createRootReducer from "~reducers/index"
import createCache from "@emotion/cache"
import { NonceProvider } from "react-select"
import { SUBMIT_FEEDBACK } from "~actions/actionTypes"

const emotionalNonce = process.env.GATSBY_EMOTION_NONCE
const emotionalKey = process.env.GATSBY_EMOTION_KEY
const styleCache = createCache({
    key: emotionalKey,
    nonce: emotionalNonce,
})
const client = axios.create({
    baseURL: baseUrl,
    headers: {
        "Content-Type": "application/vnd.api+json;charset=utf-8",
    },
    maxBodyLength: Infinity,
})
const middlewareConfig = {
    interceptors: {
        request: [
            {
                success: function (
                    { getState, dispatch, getSourceAction },
                    req
                ) {
                    const accessToken = getState().member.jwtToken
                    const isRefreshingToken =
                        getState().member.isRefreshingToken
                    dispatch(clearApiErrors())
                    if (accessToken) {
                        req.headers["Authorization"] = `Bearer ${accessToken}`
                    }
                    if (!isRefreshingToken) {
                        tryAndRefresh(getState, dispatch)
                    }
                    return req
                },
                error: function (
                    { getState, dispatch, getSourceAction },
                    error
                ) {
                    dispatch(setApiErrors(error))
                    return error
                },
            },
        ],
        response: [
            {
                success: function (
                    { getState, dispatch, getSourceAction },
                    res
                ) {
                    return Promise.resolve(res)
                },
                error: function (
                    { getState, dispatch, getSourceAction },
                    error
                ) {
                    const tokenExpires = getState().member.tokenExpires
                    if (
                        tokenExpires !== undefined &&
                        outsideTokenRefreshRange(tokenExpires)
                    ) {
                        dispatch(logoutUser())
                        navigate("/portal/login", { replace: true })
                    }
                    if (error.response.status === 403) {
                        dispatch(logoutUser())
                        navigate("/portal/login", { replace: true })
                    }
                    if (error.response.status === 422) {
                        const { errors } = error.response.data
                        dispatch(setApiErrors(errors))
                    }
                    if (error.response.status === 401) {
                        const { errors } = error.response.data
                        // Exclusion for submit feedback
                        //May refactor include multiple clients see https://github.com/svrcekmichal/redux-axios-middleware?tab=readme-ov-file#multiple-clients
                        if (
                            getSourceAction(error.config)?.type !==
                            SUBMIT_FEEDBACK.type
                        ) {
                            dispatch(setApiErrors(errors))
                            dispatch(logoutUser())
                            navigate("/portal/login", { replace: true })
                        }
                    }
                    if (error.response.status === 423) {
                        const { errors } = error.response.data
                        dispatch({ type: "SET_RESET" })
                        if (errors[0]?.field === "email") {
                            navigate("/portal/something-went-wrong", {
                                state: {
                                    title: "You're not quite there yet",
                                    shouldReset: true,
                                    message:
                                        "In order to log in to Gretel, we just need you to verify your email address " +
                                        "and set your password. When you click the button below, you'll jump over " +
                                        "to our email verification screen.",
                                    primaryCta: {
                                        label: "Continue",
                                        action: "/portal/resend-verification-email",
                                    },
                                },
                            })
                        } else {
                            navigate("/portal/something-went-wrong", {
                                state: {
                                    title: 'Password reset required',
                                    shouldReset: true,
                                    message:
                                        "Your account is currently locked. In order to log in we need you to " +
                                        "reset your password. When you click the button below, you'll jump over " +
                                        "to our password reset screen.",
                                    primaryCta: {
                                        label: "Continue",
                                        action: "/portal/forgot-password?reset=true",
                                    },
                                },
                            })
                        }
                    }
                    if (error.response.status === 503) {
                        const { errors } = error.response.data
                        navigate("/portal/503", {
                            replace: true,
                            state: { errors },
                        })
                    }
                    return Promise.reject(error)
                },
            },
        ],
    },
}

let middleware = [thunk]
if (process.env.NODE_ENV !== "production") {
    // const { logger } = require(`redux-logger`)
    // const logger = createLogger({
    //     collapsed
    // })
    middleware = [...middleware]
}
// eslint-disable-next-line import/no-anonymous-default-export
export default ({ element }) => {
    const persistConfig = {
        key: "root",
        storage: AsyncStorage,
        blacklist: [],
    }

    const persistedReducer = persistReducer(persistConfig, createRootReducer)
    const store = createStore(
        persistedReducer,
        composeWithDevTools(
            applyMiddleware(
                ...middleware,
                axiosMiddleware(client, middlewareConfig)
            )
        )
    )
    const persistor = persistStore(store)

    return (
        <NonceProvider nonce={styleCache.nonce} cacheKey={styleCache.key}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    {element}
                </PersistGate>
            </Provider>
        </NonceProvider>
    )
}
